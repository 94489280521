import React, { Component } from 'react';
import './App.css';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import enrichLogo from './enrich_consortium_logo.png';
import propertyInvestment from './home.svg';
import consultancy from './computer.svg';
import capitalInvestment from './plant.svg';
import merchantile from './international.svg';
import phone from './phone.svg';
import mail from './mail.svg';
import search from './loupe.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  render() {
    return (
      <div className="App">
        <Container>
          <div id='header'>
            <div>
              <div className="headline">
                <div>
                  <span className="siteTitle">
                    <img src={enrichLogo} alt="Enrich Consortium" width='500px' style={{ maxWidth: '85%', marginBottom: '15px' }} />
                  </span>
                </div>
                <div>
                  <span className="siteSubTitle" style={{ marginLeft: '10px', marginRight: '10px' }}>
                    Property | Mercantile | Investment
                </span>
                </div>
              </div>
            </div>
          </div>
          <Row id="services">
            <Col xs={{ span: 10, offset: 1 }} md={4} style={{ textAlign: 'center', marginTop: '0px', marginBottom: '80px' }}>
              <img src={propertyInvestment} alt="Property Investment" style={{ width: '100px' }} />
              <div className="bodyText">
                <h3>PROPERTY INVESTMENT</h3>
                <span>
                  At Enrich Consortium we work closely with our clients to find them the exact property that they're after; whether it's their first home, a new investment or addition to their portfolio, or if they're downsizing for retirement. We are recommended by some of the finest mortgage brokers, accountants and lenders, and we work closely with the best builders, and the most experienced and trusted developers across Australia.
                  <br />
                </span>
              </div>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} md={4} style={{ textAlign: 'center', marginTop: '0px' }}>
              <img src={consultancy} alt="Property Investment" style={{ width: '100px' }} />
              <div className="bodyText">
                <h3>DEVELOPMENT CONSULTANCY</h3>
                <span>
                  As property development consultants we would like to use our skills and time to understand what you want for your future development and vision. The first step we would like to take is to consult with you, using simple yet feasible analysis report of your project to help you decide whether this project is worth pursuing. Enrich Realty Group's development consultants have years of experience and knowledge of the Melbourne market and property development. We have strong local and global research capabilities which helps us draw upon the vast skills of senior research and professional personnel on a global basis.
                  <br />
                </span>
              </div>
            </Col>
          </Row>
          <Row id="services2">
            <Col xs={{ span: 10, offset: 1 }} md={4} style={{ textAlign: 'center' }}>
              <img src={capitalInvestment} alt="Property Investment" style={{ width: '100px', marginTop: '40px' }} />
              <div className="bodyText">
                <h3>CAPITAL INVESTMENT</h3>
                <span>
                  Enrich Consortium believes that emerging Australian companies provide investors with the ability to access attractive financial returns, portfolio diversification and deliver outsized economic impacts for the broader Australian economy. Our investment approach seeks to identify the best investments within an industry. This next generation of Australian corporate success stories are often associated with high levels of productivity and innovation, as well as new employment opportunities for Australian workers.
                  <br /><br /><br />
                </span>
              </div>
            </Col>
            <Col xs={{ span: 10, offset: 1 }} md={4} style={{ textAlign: 'center' }}>
              <img src={merchantile} alt="Property Investment" style={{ width: '100px', marginTop: '40px' }} />
              <div className="bodyText">
                <h3>MERCANTILE &amp; INTERNATIONAL TRADE</h3>
                <span>
                  Whether buying or selling, Enrich Consortium can help you achieve the best possible outcome. Our expert team will guide you safely and seamlessly through each step of the transaction, with in-depth market knowledge and personalised service.
                  <br />
                </span>
              </div>
            </Col>
          </Row>

          <Jumbotron id='footer' fluid>
            <div className="headline">
              <div>
                <span className="siteSubTitle">
                  HERE FOR YOU TO
                </span>
              </div>
              <div>
                <span className="siteTitle">Contact Us</span>
              </div>
              <div>
                <span className="siteSubTitle2">
                  <strong>ENRICH CONSORTIUM</strong>
                </span>
              </div>
              <div>
                <span className="siteSubTitle2" style={{ color: '#ffffff' }}>
                  LEVEL 3, 257 COLLINS STREET, MELBOURNE VIC 3000 AUSTRALIA
                </span>
              </div>
              <div style={{ marginTop: '30px' }}>
                <img className="filter-white" src={phone} alt="Call Enrich Consortium" style={{ height: '22px' }} />
                <span className="contactDetails">
                  <a href="tel:(03) 8375 9648">(03) 8375 9648</a>
                </span>
              </div>
              <div style={{ marginTop: '10px' }}>
                <img className="filter-white" src={mail} alt="Email Enrich Consortium" style={{ height: '22px' }} />
                <span className="contactDetails">
                  <a href="mailto:info@enrichconsortium.com">info@enrichconsortium.com</a>
                </span>
              </div>
              <div style={{ marginTop: '10px' }}>
                <img className="filter-white" src={search} alt="Search Enrich Consortium" style={{ height: '22px' }} />
                <span className="contactDetails">
                  <a href="enrichconsortium.com">enrichconsortium.com</a>
                </span>
              </div>
            </div>
          </Jumbotron>

        </Container>
      </div>
    );
  }
}

export default App;
